<template>
  <div class="app">
    <el-dialog
      title="新增城市站点"
      :visible.sync="dialogSendVisible"
      width="30%"
      :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item
          label="选择省份"
          prop="province"
          :rules="[{ required: true, message: '请选择城市', trigger: 'blur' }]"
        >
          <el-select
            v-model="form.province"
            @change="setArea"
            filterable
            placeholder="请选择省份"
            style="width: 100%"
          >
            <el-option
              v-for="rs in cityAreaData"
              :key="rs.code"
              :label="rs.name"
              :value="rs.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选择城市"
          prop="city"
          :rules="[{ required: true, message: '请选择城市', trigger: 'blur' }]"
        >
          <el-select
            v-model="form.city"
            filterable
            @change="setCity"
            placeholder="请选择城市"
            style="width: 100%"
          >
            <el-option
              v-for="rs in cityAreaData2"
              :key="rs.code"
              :label="rs.name"
              :value="rs.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="排序"
          prop="sort"
          :rules="[{ required: true, message: '输入排序', trigger: 'blur' }]"
        >
          <el-input-number v-model="form.sort"></el-input-number>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-switch
            v-model="form.isShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      formCancel: "",
      cityAreaData: "",
      cityAreaData2: "",
      form: {
        province: "",
        city: "",
        sort: "",
        isShow: 1,
        code: "",
      },
      dialogSendVisible: true,
    };
  },
  methods: {
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.post(this.$api.cityAdd, this.form);
              this.handleSendClose();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    post(url, data) {
      this.$postFormData(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$parent.listData();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    setArea(val) {
      this.getCityArea(val);
    },
    setCity(val) {
      this.form.code = val;
    },
    getCityArea(val) {
      this.$postFormData(this.$api.cityArea, { parentCode: val }).then(
        (res) => {
          if (res.code == 1) {
            if (val === 1) {
              this.cityAreaData = res.data;
            } else {
              this.cityAreaData2 = res.data;
            }
          }
        }
      );
    },
  },
  created() {
    this.getCityArea(1);
  },
};
</script>
